.sc-notfound {
  background-image: url("/img/new/img_new-bg-404.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  color: var(--white);
  align-items: center;
  .content {
    position: relative;
    bottom: 3rem;
  }
  .img-wrapper {
    display: flex;
    justify-content: center;
  }
  .title {
    font-size: 1.5rem;
  }
  .description {
    font-size: 0.8125rem;
    color: rgb(231, 228, 228);
    font-weight: 100;
  }
  .btn-back {
    span {
      color: var(--white) !important;
    }
  }
}

.sc-errorpage {
  background-image: url("/img/new/img_new-bg-500.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  color: var(--primary);
  align-items: center;
  .content {
    position: relative;
    bottom: 3rem;
  }
  .wrapper {
    .title {
      font-size: 1.25rem;
      font-weight: 600;
    }
    .description {
      font-size: 0.8125rem;
      color: rgb(147, 146, 146);
      font-weight: 0;
    }
    .btn-back {
      height: 50px !important;
      border-radius: 12px !important;
      span {
        color: var(--white) !important;
      }
    }
  }
  .img-wrapper {
    display: flex;
    justify-content: center;
  }
}
