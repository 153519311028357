.sc-empty-state {
  .empty-state-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .empty-state-image {
    margin-bottom: 2.5rem;
  }
  .empty-state-title {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.625rem;
    // max-width: 85%;
    margin: 0 auto 0.5rem;
    color: var(--gray-900);
  }
  .empty-state-subtitle {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    // max-width: 80%;
    margin: 0 auto 1rem;
    color: var(--gray-3);
  }
  .empty-state-link {
    font-size: 0.875rem;
    line-height: 1rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--dark-red);
  }
}
