.sc-login {
  position: relative !important;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  .product-banner {
    position: absolute;
    right: -10px;
    top: -40px;
  }
  .sc-login-form-box {
    width: 33.3%;
    .form-login-user {
      .field {
        label {
          color: var(--black);
          opacity: 1;
        }
        &.is-invalid {
          .error-message {
            color: var(--dark-red) !important;
          }
        }
      }
      .warning-label {
        font-weight: 400;
        font-size: 0.75rem;
        color: var(--white);
        opacity: 0.8;
        line-height: 150%;
      }
    }
    @media (max-width: 1200px) {
      width: 50%;
    }
    @media (max-width: 768px) {
      width: 100%;
      .form-box {
        padding: 0 1.25rem;
        position: absolute;
        // bottom: 20px;
        left: 0;
        width: 100%;
        // margin-top: 0.25rem;
      }
    }
    .title,
    .label,
    .img-wrapper {
      @media (max-width: 768px) {
        max-width: 50%;
      }
      text-align: center;
      color: var(--black);
    }
    .label {
      font-size: 1rem;
      opacity: 0.8;
      @media (max-width: 768px) {
        width: 50%;
      }
    }
  }
  .sc-login-image-login-desktop {
    height: 40vh;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    img {
      object-fit: cover;
    }
  }
  .site-by {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.5rem;
  }
}

.deactivated-portal-class {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    opacity: 0.8;
  }
  .deactivated-info-wrapper {
    width: 100%;
    z-index: 11;
    display: flex;
    align-items: center;
    .deactivated-info-box {
      position: relative;
      background: var(--ivory);
      padding: 1.25rem;
      padding-top: 6.5rem;
      img {
        position: absolute;
        top: -25%;
        left: 50%;
        transform: translateX(-50%);
      }
      p {
        color: var(--gray-2);
      }
    }
    .close-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      cursor: pointer;
      &.claim {
        position: relative;
        bottom: -4rem;
      }
      .icon {
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        display: grid;
        place-items: center;
        position: relative;
        bottom: -40px;
        border-radius: 50%;
        border: 1.5px solid var(--white);
        i {
          color: var(--white);
          font-size: 1.5rem;
        }
      }
    }
  }
}

.verify-account-portal-class {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    opacity: 0.8;
  }
  .verify-account-info-wrapper {
    width: 100%;
    z-index: 11;
    display: flex;
    align-items: center;
    .verify-account-info-box {
      position: relative;
      background: var(--ivory);
      padding: 1.25rem;
      padding-top: 6.5rem;
      img {
        position: absolute;
        top: -25%;
        left: 50%;
        transform: translateX(-50%);
      }
      p {
        color: var(--gray-2);
      }
    }
    .close-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      cursor: pointer;
      &.claim {
        position: relative;
        bottom: -4rem;
      }
      .icon {
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        display: grid;
        place-items: center;
        position: relative;
        bottom: -40px;
        border-radius: 50%;
        border: 1.5px solid var(--white);
        i {
          color: var(--white);
          font-size: 1.5rem;
        }
      }
    }
  }
}

.sc-login-pin {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    padding: 3rem 0;
  }
  .form-box-pin {
    width: 100%;
    @media (min-width: 768px) {
      padding: 2rem 1rem;
      border: 1px solid var(--light-brown);
      border-radius: 10px;
      background-color: white;
      max-width: var(--breakpoint-sm);
    }
  }
}

.sc-login_create-pin {
  height: 100%;
  @media (min-width: 768px) {
    background-color: var(--white);
  }
  .back-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.25rem;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 700;
    p {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
  .title {
    color: var(--dark-red);
    font-size: 1.25rem;
    text-transform: uppercase;
  }
  .description {
    color: var(--gray-2);
    opacity: 0.7;
    font-size: 0.9rem;
  }
  .forgot-text {
    margin-top: 2rem;
    text-align: center;
    color: var(--dark-red);
    font-size: 12px;
    line-height: 16px;
  }
  .pin-number-text {
    font-weight: bold;
    font-size: 2rem;
    color: var(--dark-red);
  }
  .pin-wrapper {
    height: 60px;
    // border-bottom: 2px solid var(--gray-200);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9rem 0;
    // &.active {
    //   border-bottom: 3px solid var(--black);
    // }
    .pin-set {
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      border: 1px solid var(--dark-red);
      &.fill {
        background-color: var(--dark-red);
      }
    }
  }
  .input-hidden {
    position: fixed;
    left: 0;
    top: -10rem;
    opacity: 0;
  }
  .error-pin {
    font-size: 0.9rem;
    color: var(--danger);
    text-align: center;
  }
}
