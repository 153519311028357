// .nav-top {
//   padding: 0 !important;
//   &.with-bg {
//     -webkit-transition: background 0.7s linear;
//     -moz-transition: background 0.7s linear;
//     -o-transition: background 0.7s linear;
//     -ms-transition: background 0.7s linear;
//     transition: background 0.7s linear;
//     background: var(--bg-primary);
//     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
//       rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
//     .wrapper {
//       border-bottom: 0;
//       .burger-menu {
//         .line {
//           background-color: var(--dark) !important;
//         }
//       }
//       .col-right {
//         i {
//           color: var(--dark) !important;
//         }
//       }
//     }
//   }
//   // background-color: var(--bg-primary);
//   position: -webkit-sticky; /* Safari */
//   position: sticky;
//   top: 0;
//   &.light {
//     .wrapper {
//       .burger-menu {
//         .line {
//           background-color: var(--white);
//         }
//       }
//       .col-right {
//         i {
//           color: var(--white);
//         }
//       }
//     }
//   }
//   &.nav-staff {
//     .wrapper {
//       border-bottom: unset;
//       height: 81px;
//     }
//   }
//   .wrapper {
//     height: 67px;
//     width: 100%;
//     border-bottom: 1px solid rgba(91, 23, 28, 0.2);
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     .col-right {
//       &.staff-menu {
//         height: 100%;
//         padding-top: 1.5rem;
//       }
//       i {
//         font-size: 1.2rem;
//         color: var(--dark);
//       }
//     }
//     .burger-menu {
//       display: flex;
//       flex-direction: column;
//       gap: 4px;
//       align-items: flex-end;
//       .line {
//         height: 2px;
//         background-color: var(--dark);
//         width: 18px;
//       }
//       &.nav-staff {
//         .line {
//           height: 2px;
//           background-color: var(--dark-red);
//           width: 12px;
//           &:nth-child(2) {
//             width: 18px;
//           }
//         }
//       }
//     }
//     .nav-staff-label {
//       color: var(--dark-red);
//       text-align: center;
//       font-weight: 700;
//       font-size: 1rem;
//       padding-top: 0.5rem;
//     }
//   }
// }

.nav-top {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 3rem;
  height: 80px;
  -moz-transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  &.with-bg {
    background-color: var(--bg-primary);
    -moz-transition: all 0.1s ease-in;
    -webkit-transition: all 0.1s ease-in;
    -o-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .menu-nav-item {
      color: var(--dark-red) !important;
    }
    .user {
      i,
      span {
        color: var(--dark-red) !important;
      }
    }
  }
  @media (max-width: 992px) {
    height: 60px;
    padding: 0;
  }
  .nav-wrapper {
    &.red {
      border-bottom: 1px solid var(--light-gray);
      @media (max-width: 992px) {
        border: none;
      }
    }
    width: 100%;
    height: 100%;
    border-bottom: 1px solid var(--light-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .col-left,
    .col-right {
      min-width: 10%;
    }
    .col-middle {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .menu {
        display: flex;
        align-items: center;
        .logo {
          margin: 0 2rem;
        }
        .menu-nav {
          @media (max-width: 1140px) {
            display: none;
          }
          display: flex;
          margin: 0;
          padding: 0;
          gap: 1.25rem;
          .menu-nav-item {
            font-size: 1.25rem;
            text-transform: uppercase;
            color: var(--dark-red);
            list-style: none;
            font-size: 1.125rem;
          }
        }
      }
    }
    .col-right {
      display: flex;
      justify-content: flex-end;
      .user {
        &.red {
          i,
          span {
            color: var(--white);
          }
        }
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        i,
        span {
          color: var(--dark-red);
        }
        i {
          @media (max-width: 576px) {
            font-size: 1.25rem;
          }
          font-size: 1.5rem;
        }
      }
    }
  }
  // height: 80px;
  // @media (max-width: 512px) {
  //   height: 56px;
  // }
  // background-color: red;
  // border-bottom: 1px solid var(--light-dark);
  // position: fixed;
  // top: 0;
  // width: 100%;
  // padding: 0 3rem;
  // .navbar-wrapper {
  //   height: 100%;
  //   border-bottom: 1px solid var(--light-dark);
  //   display: flex;
  //   width: 100%;
  //   .col-start,
  //   .col-end {
  //     background-color: rebeccapurple;
  //     min-width: 200px;
  //   }
  //   .col-middle {
  //     background-color: red;
  //     flex: 1;
  //     flex-basis: 75;
  //     .menu {
  //       background-color: yellow;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //     }
  //   }
  //   .col-end {
  //     @media (max-width: 992px) {
  //       padding: 1rem;
  //     }
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //     height: 100%;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     .user {
  //       display: flex;
  //       align-items: center;
  //       i,
  //       span {
  //         cursor: pointer;
  //         color: var(--dark-red);
  //       }
  //       i {
  //         font-size: 1.25rem;
  //       }
  //       span {
  //         font-size: 1rem;
  //         @media (max-width: 992px) {
  //           display: none;
  //         }
  //         margin-left: 0.75rem;
  //       }
  //     }
  //   }
  // }
}

// ==========================================================================
// Drawer Menu
// ==========================================================================
.side-drawer-mobile {
  &.existing {
    background-color: var(--black);
    color: var(--white);
    .menu-item {
      color: var(--white) !important;
    }
  }
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  top: 0;
  position: fixed;
  height: 100%;
  overflow: auto;
  width: 100%;
  z-index: 1039;
  // transform: translateX(-100%);
  // transition-property: transform;
  // transition-duration: 1s;
  // transition-timing-function: ease(in-out-quart);
  background-color: rgba(245, 242, 237, 1);
  &.account-menu {
    transform: translateX(135%);
    transition-property: transform;
    transition-duration: 1s;
    transition-timing-function: ease(in-out-quart);
    &.open {
      // transform: translateX(0%);
    }
  }
  @media (min-width: 512px) {
    display: none;
  }
  .main-drawer-wrapper {
    position: relative;
    .menu {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      .menu-item {
        padding: 1.25rem 0;
        font-size: 1.125rem;
        text-transform: uppercase;
        text-align: center;
        color: var(--gray-2);
        font-weight: 400;
      }
    }
  }
  .drawer-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67px;
    border-bottom: 1px solid var(--light-dark);
    position: relative;
    .image-wrapper {
      img {
        object-fit: contain;
      }
    }
  }
  &.open {
    transform: translateX(0);
  }
  .account-button {
    position: absolute;
    left: 1.5rem;
    i {
      font-size: 1.5rem;
    }
  }
  .close-button {
    position: absolute;
    right: 1.5rem;
    i {
      font-size: 1.5rem;
    }
  }
  .side-drawer-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fffbf4;
    padding: 1.125rem 1.25rem;
    height: 3.25rem;
    .site-by {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 0.75rem;
      color: var(--quincy);
      img {
        object-fit: contain;
      }
    }
  }
}

.times-icon {
  position: absolute;
  top: 0.75rem;
  right: 1.5rem;
  font-size: 1.5rem;
  display: flex;
  z-index: 100000;
}

.account-icon {
  z-index: 100000;

  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
  font-size: 1.25rem;
  display: flex;
}
