.sc-user-register {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  @media (max-width: 512px) {
    display: block;
    padding: 0.5rem 0;
  }
  .form-box-register {
    padding: 2rem 1rem;
    border: 1px solid var(--light-brown);
    border-radius: 10px;
    .back-btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 1.25rem;
      cursor: pointer;
      font-size: 0.75rem;
      font-weight: 700;
      p {
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
    .title {
      color: var(--dark-red);
      font-size: 1.25rem;
      text-transform: uppercase;
    }
    .description {
      color: var(--gray-2);
      opacity: 0.7;
      font-size: 0.9rem;
      //   color: var(--light-gray);
    }
    width: 33.3%;
    @media (max-width: 1200px) {
      width: 75%;
    }
    @media (max-width: 768px) {
      border: none;
      width: 100%;
      padding: 0;
      background: transparent;
      .title,
      .description {
        text-align: center;
      }
      .description {
        color: var(--gray-2);
        opacity: 0.7;
      }
    }
    background-color: white;
  }
}
