.sc-barcode {
  position: relative;
  .image-product {
    padding-bottom: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .sc-barcode__content_barcode_image_wrapper {
    margin: 0.5rem 0;
    height: 100px;
    position: relative;
    img {
      object-fit: contain;
    }
  }
  .sc-barcode__content {
    position: relative;
    background-color: rgba(234, 221, 205, 1);
    min-height: 400px;
    padding: 3rem 0 2rem 0;
    .sc-barcode__content_title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.5rem;
      .sc-barcode__content_title {
        font-size: 1.125rem;
        font-weight: bold;
        color: var(--gray-900);
        margin-bottom: 0;
      }
      .sc-barcode__content_refresh-btn {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        border-radius: 2rem;
        border: 1px solid var(--primary);
        color: var(--primary);
        padding: 0.5rem 0.75rem;
      }
    }
    .sc-barcode__content_text {
      color: var(--gray-900);
    }
    .sc-barcode__content_text_tc {
      color: var(--red);
      font-weight: 700;
      font-size: 0.75rem;
    }
    .sc-barcode__content_barcode_wrapper {
      position: absolute;
      top: -30%;
      left: 0;
      width: 100%;
      display: flex;
      // min-height: 200px;
      justify-content: center;
      align-items: center;
      .sc-barcode__content_barcode_info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          margin: 0;
          color: var(--black);
          font-weight: 500;
          font-size: 0.9rem;
        }
      }
      .sc-barcode__content_barcode_title {
        font-size: 0.9rem;
        text-align: center;
        letter-spacing: 0.5px;
      }
      .sc-barcode__content_barcode {
        //   height: 200px;
        background-color: var(--white);
        width: 90%;
        border-radius: 10px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .sc-barcode__stamps-list {
    justify-content: center;
    margin-top: 1rem;
    .sc-barcode__stamps-item {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      height: 70px;
      .sc-barcode__stamps-item-img-placeholder {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
      .sc-barcode__stamps-item-img {
        z-index: 2;
        &.variant-1 {
          transform: translate3d(2.5px, 2.5px, 0px);
        }
        &.variant-2 {
          transform: translate3d(-5px, -4.5px, 0px);
        }
        &.variant-3 {
          transform: translate3d(3.5px, 0px, 0px);
        }
      }
    }
  }
  .home-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e0d5c7;
    padding: 1.125rem 1.25rem;
    height: 3.25rem;
    .site-by {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 0.75rem;
      color: var(--quincy);
      img {
        object-fit: contain;
      }
    }
  }
}
