@use "../main-dart/variables/grid" as grid;

.sc-user-settings {
  height: 100%;
  .title-header {
    //  font-size: 1.25rem;
    color: var(--black);
  }
  .account-box {
    margin-bottom: 1.25rem;
    padding: 0.5rem 1.125rem;
    border: 1px solid rgb(218, 192, 192);
    border-radius: 12px;
    &.disabled {
      border: 1px solid var(--gray-200);
      background-color: var(--gray-100);
    }
    .account-box_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .account-box_value {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .label {
          margin-bottom: 0;
          font-size: 0.75rem;
          color: var(--gray-3);
        }
        .value {
          color: var(--gray-2);
          margin-bottom: 0;
          &.disabled {
            color: var(--gray-3);
          }
        }
      }
      .account-box_btn {
        .edit-btn {
          cursor: pointer;
          margin-bottom: 0;
          font-weight: 500;
        }
      }
    }
    &.with-warning {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0;
    }
  }
  .account-box_warning {
    padding: 0.5rem 1.125rem;
    border: 1px solid rgb(218, 192, 192);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top: 0;
    background: rgba(91, 23, 28, 0.1);
    margin-bottom: 1.25rem;
    text-align: center;
    p {
      // margin-bottom: 0.5rem;
      color: var(--gray-2);
      font-size: 0.75rem;
    }
    .add-btn {
      font-size: 0.75rem;
      color: var(--dark-red);
      cursor: pointer;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}
.sc-setting-email {
  .sc-setting-email__title {
    text-transform: capitalize;
    color: var(--dark-red);
  }
  .email-setting-form {
    .email-setting-form__bottom {
      position: fixed;
      bottom: 1rem;
      width: calc(100% - 2.5rem) !important;
      .email-setting-form__info {
        font-size: 0.75rem;
        color: var(--gray-2);
        font-weight: 400;
      }
    }
  }
  @media screen and (min-width: grid.$md) {
    .email-setting-form {
      .email-setting-form__bottom {
        position: absolute;
        bottom: -2.5rem;
        width: 100% !important;
      }
    }
  }
}

.sc-verify-your-email {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 1rem;
  }
  .description {
    text-align: center;
  }
  .resend-link {
    margin-top: 2rem;
    display: flex;
    .label {
      color: var(--black);
      font-size: 0.75rem;
    }
    .resend {
      font-size: 0.75rem;
      margin-left: 0.5rem;
      color: var(--dark-red);
      font-weight: 600;
    }
  }
  .btn-to-setting {
    position: fixed;
    bottom: 1.5rem;
    left: 1rem;
    right: 1rem;
    width: calc(100% - 2rem);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// modal send email

.modal-send-email {
  .modal-body {
    .modal-content {
      padding-top: 5rem;
      padding-bottom: 3.5rem;
      img {
        position: absolute;
        top: -5rem;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      .modal-title,
      .modal-text {
        text-align: center;
      }
      .modal-title {
        font-size: 1.5rem;
        color: var(--primary);
      }
      .modal-text {
        margin-bottom: 2.5rem;
      }
    }
  }
}
