.countdown-timer {
    .resend {
        cursor: pointer;
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: var(--red);
        text-transform: uppercase;
        &.text-muted {
            cursor: default;
            color: var(--gray-3) !important;
        }
        span {
            text-transform: none;
            font-size: 0.75rem;
        }
    }
}
