.sc-user-history {
  height: 100%;
  flex: 1;
  .user-history-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-text {
      margin: 0;
      font-size: 1.75rem;
      font-weight: 300;
      text-transform: capitalize;
      color: var(--dark-red);
    }
    .filter-button {
      font-size: 0.8rem;
      height: 80%;
      text-transform: uppercase;
      padding: 0rem 1rem;
      background: transparent;
      font-weight: 700;
      border-radius: 50px;
      color: var(--dark-red);
      border: 1.5px solid var(--dark-red);
    }
  }
  .user-history-blank-state {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  .user-history-list {
    flex: 1;
    overflow-y: scroll;
    height: 100%;
    .user-history-item {
      // height: calc(100% / 5);
      border-bottom: 1px solid var(--gray-400);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      > div {
        p {
          font-weight: 400;
        }
        .user-history-item_label {
          font-size: 0.75rem;
          color: rgba(var(--dark), 0.5);
          margin-bottom: 0.5rem;
        }
        .user-history-item_title {
          color: var(--gray-2);
          margin-bottom: 0.25rem;
        }
        .user-history-item_code {
          color: var(--dark-red);
          margin-bottom: 0;
          font-size: 0.875rem;
        }
        .user-history-item_count {
          color: var(--gold);
          font-size: 0.875rem;
          margin-right: 0.5rem;
          margin-bottom: 0;
        }
      }
    }
  }
}

.modal-filter-voucher,
.modal-filter-stamps {
  .modal-title {
    color: var(--dark-red);
    font-size: 1.5rem;
  }
}
.modal-detail-user-history {
  .modal-wrapper {
    background: rgba(var(--white), 0);
    .close {
      bottom: -4rem;
      top: unset;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid var(--white);
      border-radius: 100%;
      i {
        color: var(--white);
        font-size: 2rem;
      }
    }
    .modal-body {
      .modal-content {
        background: rgba(var(--white), 0);
        padding: 0.5rem;
        .modal-detail-user-history_wrapper {
          width: 100%;
          position: relative;
          .modal-detail-user-history_wrapper-img {
            position: absolute;
            top: -8rem;
            display: flex;
            justify-content: center;
            width: 100%;
            img {
              transform: rotate(-6.49deg);
            }
          }
          .modal-detail-user-history_top {
            background: url("/img/common/substract-top.png") bottom center
              no-repeat;
            background-size: contain;
            height: 250px;
            padding: 4.5rem 1rem 0rem 1rem;
            .modal-detail-user-history_top_title {
              color: var(--black);
              max-width: 65%;
            }
            .modal-detail-user-history_top_description {
              color: rgba(var(--gray-2), 0.7);
              font-size: 0.875rem;
            }
          }
          .modal-detail-user-history_bottom {
            background: url("/img/common/substract-bottom.png") top center
              no-repeat;
            background-size: contain;
            height: 230px;
            padding: 0rem 1rem 2.5rem 1rem;
            // margin-top: -2rem;
            text-align: center;
            .modal-detail-user-history_bottom_expired-date {
              color: var(--black);
              font-size: 0.625rem;
              padding-top: 2rem;
            }
            .modal-detail-user-history_bottom_code {
              margin-top: 0.5rem;
              color: var(--black);
            }
          }
        }
      }
    }
  }
}
