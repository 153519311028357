.sc-whatsapp-verify {
  height: calc(100vh - 80px);
  .back-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.25rem;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 700;
    p {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
  .container {
    height: 100%;
  }
  .whatsapp-verify-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 2.5rem;
    h1,
    p {
      text-align: center;
    }
    h1 {
      color: var(--primary);
      margin-bottom: 0.5rem;
      letter-spacing: 0.05625rem;
      line-height: 150%; /* 1.6875rem */
    }
    p {
      color: var(--gray-2);
      letter-spacing: 0.05rem;
      line-height: 150%; /* 1.5rem */
    }
    .whatsapp-verify-bottom {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      small {
        text-align: center;
        color: var(--gray-2);
      }
    }
  }
  @media (min-width: 576px) {
    .whatsapp-verify-wrapper {
      padding-top: 1rem;
      img {
        width: 75%;
      }
      h1 {
        font-size: 1.5rem;
      }
    }
  }
}
