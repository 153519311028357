$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

.modal-bottom-position {
  margin: auto;
  @media (max-width: 512px) {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
  }
}

.modal-reward {
  position: relative;
  h1 {
    color: var(--primary);
  }
  .modal-reward_wrapper-img {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8.5rem;
    &.tada {
      top: -6.5rem;
    }
    img {
      object-fit: contain;
    }
  }
}

.offside-top {
  top: -2rem !important;
  color: var(--white);
  font-size: 2rem !important;
  font-weight: bold !important;
}

.modal_terms_conditions {
  .main-title {
    color: var(--dark-red);
  }
  .content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.25rem;
    }
  }
  .stamp-reward {
    .title {
      font-weight: bold;
      font-size: 1.25rem;
      color: var(--dark);
    }
    ul {
      padding: 0 0 0 2rem;
      li {
        margin-top: 0.5rem;
        color: var(--gray-2);
        font-size: 1rem;
      }
    }
  }
}

.modal-image-offset {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.modal_detail {
  position: relative;
  text-align: center;
  padding: 96px 0 0;
  .list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.75rem;
    padding-top: 0.75rem;
    &.bordered {
      border-bottom: 1px solid var(--dark-red);
      padding-bottom: 1rem;
    }
    &.voucher {
      border-bottom: 1px solid var(--dark-red);
      padding-top: 0;
    }
    .list-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .label {
        font-size: 0.75rem;
        color: var(--gray-3);
        margin-bottom: 0.5rem;
      }
      .text {
        font-size: 1rem;
        color: var(--dark);
        margin-bottom: 0.75rem;
      }
      .price {
        font-size: 1.25rem;
        color: var(--dark-red);
        margin-bottom: 0.75rem;
        font-weight: 700;
      }
      &.end {
        align-items: flex-end;
      }
    }
  }
  .modal_detail-action {
    padding: 1rem 0 1.5rem;
  }
}

.modal-voucher {
  background: transparent !important;
  .close {
    display: none;
  }
  .modal-voucher_content {
    background: transparent !important;
  }
  .modal-voucher_content_wrapper {
    .content-wrapper-top {
      background-image: url("/img/common//substract-top.png") !important;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 210px;
    }
    .content-wrapper-bottom {
      background-image: url("/img/common//substract-bottom.png") !important;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 175px;
    }
  }
}

.modal-cashier-modal-camera {
  @media (max-width: $sm) {
    padding: 0 !important;
    margin: 0;
    .close {
      display: none !important;
    }
    .modal-wrapper-cashier-camera {
      padding: 0;
      margin: 0;
      height: 100%;
      .modal-wrapper-cashier-camera-content {
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        .col-top {
          position: relative;
          .overlay-frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .overlay-top,
            .overlay-bottom {
              flex: 1;
            }
            .overlay-top,
            .overlay-bottom {
              background: var(--black);
              opacity: 0.6;
            }
            .overlay-middle {
              box-sizing: border-box;
              height: 170px;
              display: flex;
              .box-1,
              .box-3 {
                width: 50px;
                background: var(--black);
                opacity: 0.6;
              }
              .box-2 {
                box-sizing: border-box;

                flex: 1;
                position: relative;
                .box-top-left,
                .box-top-right,
                .box-bottom-left,
                .box-bottom-right {
                  box-sizing: border-box;
                  position: absolute;
                  width: 50px;
                  height: 50px;
                  z-index: 100;
                }
                .box-top-left {
                  left: -5px;
                  top: -5px;
                  border-top: 5px solid var(--red);
                  border-left: 5px solid var(--red);
                }
                .box-top-right {
                  right: -5px;
                  top: -5px;
                  border-top: 5px solid var(--red);
                  border-right: 5px solid var(--red);
                }
                .box-bottom-left {
                  bottom: -5px;
                  left: -5px;
                  border-bottom: 5px solid var(--red);
                  border-left: 5px solid var(--red);
                }
                .box-bottom-right {
                  bottom: -5px;
                  right: -5px;
                  border-bottom: 5px solid var(--red);
                  border-right: 5px solid var(--red);
                }
              }
            }
          }
        }
        .col-bottom {
        }
      }
    }
  }
}

.modal-camera-cashiers {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-primary);
  z-index: 100;
  overflow-y: scroll;
  .scanner-info {
    position: relative;
    display: flex;
    width: 100%;
    .info {
      width: 100%;
      .title {
        color: var(--dark-red);
        font-size: 1.75rem;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.5px;
      }
    }
    .close {
      position: absolute;
      right: 20px;
      top: 5px;
      i {
        font-weight: 100;
        font-size: 1.75rem;
      }
    }
  }
}

.modal-give-reward {
  .title,
  .info {
    letter-spacing: 0.5px;
  }
  .title {
    font-size: 1.5rem;
    color: var(--dark-red);
    font-weight: bold;
  }
  .info {
  }
}
.modal-stamps-detail {
  .modal-body {
    .modal-content {
      padding-top: 5rem;
      .img-stamp {
        position: absolute;
        top: -5rem;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
      h1 {
        text-align: center;
        margin-bottom: 1rem;
        color: var(--primary);
      }
      .row-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        div {
          &:last-child {
            text-align: right;
          }
        }
        .label {
          color: var(--gray-3);
          font-size: 0.75rem;
          margin-bottom: 0;
        }
        .value {
          color: var(--black);
          font-size: 1rem;
          &.price {
            font-weight: 700;
            color: var(--dark-red);
            font-size: 1.125rem;
          }
        }
      }
      hr {
        color: var(--red);
        margin: 0;
      }
    }
  }
}
