@use "../../../assets/plugins/anti-icons/variables" as vIcon;
//
// Buttons
//

button {
  background: white;
  &:disabled {
    background-color: var(--gray-700) !important;
    border: none;
    cursor: default !important;
  }
}

a {
  &.btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn {
  height: 55px !important;
  position: relative;
  font-weight: 700 !important;
  font-size: 12px !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &.loading,
  &.success {
    span,
    &:after,
    &:before {
      visibility: hidden;
    }
    .el-loader {
      &:before,
      &:after {
        height: 1rem;
        width: 1rem;
        line-height: 1rem;
        top: 50%;
        margin-top: -0.5rem;
        left: 50%;
        margin-left: -0.5rem;
        position: absolute;
        font-style: normal;
      }
    }
  }
  &.loading {
    pointer-events: none;
    .el-loader {
      animation: fadeIn 0.2s linear;
      &:before {
        font-family: "Anti Icons Regular";
        content: vIcon.varIcon(vIcon.$ai-circle);
        opacity: 0.4;
        z-index: 1;
        font-size: 1rem;
      }
      &:after {
        font-family: "Anti Icons Regular";
        content: vIcon.varIcon(vIcon.$ai-spinner-third);
        animation: spin 2s infinite linear;
        z-index: 2;
        font-size: 1rem;
      }
    }
  }
  &.success {
    pointer-events: none;
    .el-loader {
      &:after {
        font-family: "Anti Icons Regular";
        content: vIcon.varIcon(vIcon.$ai-check);
        animation: flash 1s linear;
      }
    }
  }

  &.hover-underline {
    padding: 0.25rem 0;

    &::before {
      bottom: 0;
    }
  }
}

// Button link
.btn-link {
  padding-left: 0;
  padding-right: 0;

  &:focus,
  &:active {
    box-shadow: none;
  }
}

// Button Light / White
.btn-light,
.btn-white {
  color: var(--primary);
  &:hover,
  &:focus,
  &:active {
    color: var(--primary);
  }
}

// Button group
// Set display block to make .text-* on parent element / its own element
// affects the button children element
.btn-group {
  display: block;
}

.btn-primary,
.btn-outline-primary,
.btn-secondary,
.btn-outline-secondary,
.btn-white {
  border-radius: 0.5rem;
}

.btn-primary,
.btn-outline-primary,
.btn-secondary,
.btn-outline-secondary {
  &:not(.btn-sm) {
    height: 45px;
    padding: 0.25rem 3rem !important;
  }
}

.btn-link {
  outline: none !important;
}

.btn-primary {
  background-color: var(--dark-red) !important;
}

.btn-maroon {
  background-color: red !important;
}

.btn-fluid {
  width: 100%;
}

.btn-green {
  color: var(--green) !important;
  &::before {
    background-color: var(--green) !important;
  }
}

.btn {
  box-shadow: none !important;
}

.btn-transparent-white {
  border: 2px solid var(--white) !important;
  background: transparent !important;
  color: var(--white) !important;
}

// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// Override style on .buttons-custom.scss instead
// unless you want to upgrade the component version

// ========================================================================================================================================
// Buttons
// ========================================================================================================================================

// button {
//   &:disabled {
//     cursor: default !important;
//   }
// }

// .btn {
//   position: relative;
//   @include font-size($font-size-base);
//   .el-loader {
//     display: none;
//   }
//   &.loading,
//   &.success {
//     span,
//     &:after,
//     &:before {
//       visibility: hidden;
//     }
//     .el-loader {
//       display: block;
//       &:before,
//       &:after {
//         height: 1rem;
//         width: 1rem;
//         line-height: 1rem;
//         top: 50%;
//         margin-top: -0.5rem;
//         left: 50%;
//         margin-left: -0.5rem;
//         position: absolute;
//         font-style: normal;
//       }
//     }
//   }
//   &.loading {
//     pointer-events: none;
//     .el-loader {
//       animation: fadeIn 0.2s linear;
//       &:before {
//         font-family: "Anti Icons Regular";
//         content: var($ai-circle);
//         opacity: 0.4;
//         z-index: 1;
//         font-size: 1rem;
//       }
//       &:after {
//         font-family: "Anti Icons Regular";
//         content: var($ai-spinner-third);
//         animation: spin 2s infinite linear;
//         z-index: 2;
//         font-size: 1rem;
//       }
//     }
//   }
//   &.success {
//     pointer-events: none;
//     .el-loader {
//       &:after {
//         font-family: "Anti Icons Regular";
//         content: var($ai-check);
//         animation: flash 1s linear;
//       }
//     }
//   }
// }

// // Button link
// .btn-link {
//   padding-left: 0;
//   padding-right: 0;
//   &:focus,
//   &:active {
//     box-shadow: none;
//   }
// }

// // Button Light / White
// .btn-light,
// .btn-white {
//   color: $dark;
//   @include hover-focus-active {
//     color: $dark;
//   }
// }

// // Button group
// // Set display block to make .text-* on parent element / its own element
// // affects the button children element
// .btn-group {
//   display: block;
// }

// .btn {
//   &.btn-ic-left {
//     padding-left: 2.5rem;
//   }
//   &.btn-ic-right {
//     padding-right: 2.5rem;
//   }
//   .el-ai-before {
//     &:before {
//       top: 50%;
//       transform: translateY(-50%);
//       left: 1rem;
//       position: absolute;
//     }
//   }
//   .el-ai-after {
//     &:before {
//       top: 50%;
//       transform: translateY(-50%);
//       right: 1rem;
//       position: absolute;
//     }
//   }
//   &.btn-link {
//     .el-ai-before {
//       &:before {
//         left: 0;
//         transition: $transition-base;
//       }
//     }
//     .el-ai-after {
//       &:before {
//         right: 0;
//         transition: $transition-base;
//       }
//     }
//     &.btn-ic-left {
//       padding-left: 1rem;
//       &:before {
//         display: none;
//       }
//       &:hover {
//         .el-ai-before {
//           &:before {
//             left: -0.25rem;
//           }
//         }
//       }
//     }
//     &.btn-ic-right {
//       padding-right: 1rem;
//       &:before {
//         display: none;
//       }
//       &:hover {
//         .el-ai-after {
//           &:before {
//             right: -0.25rem;
//           }
//         }
//       }
//     }
//   }
// }
