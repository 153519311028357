input {
  // font-size: 1rem;
}

// Text Area
textarea {
  resize: none;
}

// Form Text
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--gray-400);
}
.invalid-feedback,
.valid-feedback {
  display: block;
}
.invalid-feedback {
  @extend .form-text;
  //   color: $danger;
}
.valid-feedback {
  @extend .form-text;
  //   color: $success;
}

// Input Group
.input-group {
  > .input-group-prepend {
    > .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  > .input-group-append {
    > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  > .field {
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:last-child) {
      .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.show-pass-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  i {
    color: var(--black);
  }
}
svg {
  color: var(--black) !important;
}

.form-control-wrapper {
  position: relative;
}

// Field

.field {
  label {
    font-size: 0.75rem;
    color: var(--white);
    opacity: 0.5;
  }
  &.dark {
    label {
      color: var(--gray-3);
      opacity: 1;
    }
  }
  /** checkbox */
  .checkbox {
    // display: flex;
    // align-items: center;
    // input[type="checkbox"] {
    //   width: 1.125rem;
    //   height: 1.125rem;
    // }
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    label {
      font-size: 1rem;
      // margin-left: 1rem;
      margin-bottom: 0;
      color: var(--gray-2);
      opacity: 1;
      cursor: pointer;
    }
    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        background-color: var(--dark-red);
        &::after {
          display: block;
        }
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      left: 0;
      height: 1.125rem;
      width: 1.125rem;
      background-color: transparent;
      border: 1px solid var(--dark-red);
      border-radius: 0.125rem;
      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 0.35rem;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid var(--white);
        border-width: 0 1.5px 1.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}
