@use "../../../assets/plugins/anti-icons/variables" as vIcon;

.error-message {
  font-size: 0.75rem;
  span {
    text-transform: capitalize;
  }
}

.border-error {
  border: 1px solid var(--red) !important;
}

.text-label {
  font-weight: 100 !important;
  color: var(--gray-3);
  letter-spacing: 0.5px;
}

.text-voucher {
  color: var(--dark-red);
}

.dynamic-voucher-wrapper {
  display: flex;
  .dynamic-voucher {
    flex: 1;
  }
  .icon-wrapper {
    display: grid;
    place-items: center;
    i {
      font-size: 1.5rem;
    }
  }
}

.form-control-wrapper {
  &.prefix {
    .input.wrapper {
      position: relative;
      input {
        padding-left: 3.5rem;
      }
    }
  }
  .prefix-content {
    position: absolute;
    padding-left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .form-control {
    font-family: var(--font-family-sans-serif);
    font-weight: 400;
    color: var(--black);
    &:disabled {
      color: var(--gray-3);
      font-weight: 400;
    }
  }
}

// Date picker

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    .datepicker_input-wrapper {
      position: relative;
      i {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
