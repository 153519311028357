@font-face {
  font-family: 'Anti Icons Brands';
  src:  url('fonts/icomoon.eot?i0ub5v');
  src:  url('fonts/icomoon.eot?i0ub5v#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?i0ub5v') format('truetype'),
    url('fonts/icomoon.woff?i0ub5v') format('woff'),
    url('fonts/icomoon.svg?i0ub5v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aib"]{
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Anti Icons Brands' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ai-tiktok:before {
  content: "\e91f";
}
.ai-android:before {
  content: "\e900";
}
.ai-app-store:before {
  content: "\e901";
}
.ai-app-store-ios:before {
  content: "\e902";
}
.ai-apple:before {
  content: "\e903";
}
.ai-apple-pay:before {
  content: "\e904";
}
.ai-btc:before {
  content: "\e905";
}
.ai-cc-mastercard:before {
  content: "\e906";
}
.ai-cc-paypal:before {
  content: "\e907";
}
.ai-cc-visa:before {
  content: "\e908";
}
.ai-facebook:before {
  content: "\e909";
}
.ai-facebook-f:before {
  content: "\e90a";
}
.ai-facebook-messenger:before {
  content: "\e90b";
}
.ai-facebook-square:before {
  content: "\e90c";
}
.ai-google:before {
  content: "\e90d";
}
.ai-google-play:before {
  content: "\e90e";
}
.ai-instagram:before {
  content: "\e90f";
}
.ai-instagram-square:before {
  content: "\e910";
}
.ai-line:before {
  content: "\e911";
}
.ai-linkedin:before {
  content: "\e912";
}
.ai-linkedin-in:before {
  content: "\e913";
}
.ai-paypal:before {
  content: "\e914";
}
.ai-pinterest:before {
  content: "\e915";
}
.ai-pinterest-p:before {
  content: "\e916";
}
.ai-pinterest-square:before {
  content: "\e917";
}
.ai-twitter:before {
  content: "\e918";
}
.ai-twitter-square:before {
  content: "\e919";
}
.ai-unsplash:before {
  content: "\e91a";
}
.ai-whatsapp:before {
  content: "\e91b";
}
.ai-whatsapp-square:before {
  content: "\e91c";
}
.ai-youtube:before {
  content: "\e91d";
}
.ai-youtube-square:before {
  content: "\e91e";
}
