.sc-home {
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: url("/img/bg/bg_png-yellow.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 338px;
    // flex: 1.25;
    .header {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 1.25rem;
        color: var(--black);
        text-transform: uppercase;
      }
      .header_refresh-btn {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        border-radius: 2rem;
        border: 1px solid var(--primary);
        color: var(--primary);
        padding: 0.5rem 0.75rem;
        cursor: pointer;
      }
    }
    .terms-conditions-label {
      cursor: pointer;
      color: var(--red);
      font-weight: normal;
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    .barcode-content {
      width: 100%;
      position: relative;
      .barcode-box {
        border-radius: 10px;
        background-color: white;
        min-height: 150px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
        .label {
          color: rgba(51, 51, 51, 1);
          opacity: 0.7;
          font-size: 0.8rem;
        }
        // .barcode {
        //   width: 100%;
        //   height: 95px;
        // }
        .img-barcode-wrapper {
          position: relative;
          height: 125px;
          width: 100%;
          img {
            object-fit: contain;
          }
        }
        .user {
          display: flex;
          justify-content: space-between;
          .name,
          .phone {
            margin: 0;
          }
          .name {
            font-size: 1rem;
            color: var(--black);
            font-weight: 500;
          }
          .phone {
            font-size: 1rem;
            color: var(--black);
            font-weight: 500;
          }
        }
      }
      .img-wrapper {
        position: absolute;
        right: 0;
        top: -50px;
      }
    }
  }
  .bottom {
    flex: 1;
    background-color: var(--brown);
    .desktop-stamp {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      // .stamp {
      //   min-height: 100px;
      //   width: 20%;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   padding: 5px;
      //   .img-wrapper {
      //     // background-color: red;
      //   }
      // }
      .desktop-stamp__item {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        height: 70px;
        .desktop-stamp__item-img-placeholder {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
        }
        .desktop-stamp__item-img {
          z-index: 2;
          &.variant-1 {
            transform: translate3d(5px, 5px, 0px);
          }
          &.variant-2 {
            transform: translate3d(-5px, -5px, 0px);
          }
          &.variant-3 {
            transform: translate3d(7.5px, 0px, 0px);
          }
        }
      }
    }
  }
}
