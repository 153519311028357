@font-face {
  font-family: 'Eatlah Icons';
  src:  url('fonts/icomoon.eot?28qzws');
  src:  url('fonts/icomoon.eot?28qzws#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?28qzws') format('truetype'),
    url('fonts/icomoon.woff?28qzws') format('woff'),
    url('fonts/icomoon.svg?28qzws#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ei"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Eatlah Icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* line-height: 1; */

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ei-add:before {
  content: "\e900";
}
.ei-barcode:before {
  content: "\e901";
}
.ei-calendar:before {
  content: "\e902";
}
.ei-check:before {
  content: "\e903";
}
.ei-close:before {
  content: "\e904";
}
.ei-eye:before {
  content: "\e905";
}
.ei-filter .path1:before {
  content: "\e906";
  color: rgb(217, 217, 217);
}
.ei-filter .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ei-filter .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(217, 217, 217);
}
.ei-filter .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ei-filter .path5:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(217, 217, 217);
}
.ei-filter .path6:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ei-history:before {
  content: "\e90c";
}
.ei-info:before {
  content: "\e90d";
}
.ei-location:before {
  content: "\e90e";
}
.ei-menu-burger-2:before {
  content: "\e90f";
}
.ei-profile:before {
  content: "\e910";
}
.ei-search:before {
  content: "\e911";
}
.ei-sort:before {
  content: "\e912";
}
.ei-trash:before {
  content: "\e913";
}
.ei-warning:before {
  content: "\e914";
}
