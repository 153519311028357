.sc-otp {
  padding-top: 1.5rem;
  .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--black);
    text-transform: uppercase;
    @media (min-width: 768px) {
      text-transform: capitalize;
    }
  }
  .message-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: 968px) {
      margin-top: 2rem;
    }
    .message {
      width: 75%;
      color: var(--gray-2);
      opacity: 0.7;
      text-align: left;
      font-size: 0.875rem;
    }
  }
  // .resend {
  //   font-size: 0.9rem;
  //   font-weight: 600;
  //   letter-spacing: 0.5px;
  //   color: var(--gray-700);
  //   &.error {
  //     color: var(--red);
  //   }
  //   span {
  //     text-transform: none;
  //     font-size: 0.75rem;
  //   }
  // }
  .error-text {
    color: var(--red);
    font-size: 0.75rem;
    letter-spacing: 0.5px;
  }
  .otp-input {
    width: 100%;
    .error-otp {
      border: 1px solid var(--red);
    }
    .container-otp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .input-otp {
        flex: 1;
        height: 68px;
        font-size: 2rem;
        background: var(--gray-50);
        border: 1px solid var(--gray-300);
        width: 100% !important;
      }
      .focus-otp {
        &:focus {
          outline: none;
        }
        border: 1px solid var(--black);
      }
    }
  }

  // containerStyle={{
  //   display: "flex",
  //   justifyContent: "space-between",
  //   alignItems: "center",
  //   gap: "10px",
  // }}
  //  inputStyle={{
  //    flex: 1,
  //    width: "100%",
  //    height: "68px",
  //    fontSize: "2rem",
  //    background: "#F2F2F2",
  //    border: "1px solid #BDBDBD",
  //  }}
}
