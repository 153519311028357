@use "../../scss/main-dart/variables/grid";

.bd-xl-satu-lite {
  .package-tables__item-header {
    img {
      margin-bottom: 1rem;
    }
  }
}

h1,
.h1 {
  font-size: 28px;

  @media (min-width: grid.$md) {
    font-size: 40px;
  }
}
