.sc-user-vouchers {
  height: 100%;
  flex: 1;
  .voucher-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-text {
      margin: 0;
      font-size: 1.7rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      color: var(--dark-red);
    }
    .filter-button {
      font-size: 0.8rem;
      height: 80%;
      text-transform: uppercase;
      padding: 0.3rem 1rem;
      background: transparent;
      font-weight: 700;
      border-radius: 50px;
      color: var(--dark-red);
      border: 1.5px solid var(--dark-red);
    }
  }
  .vouchers-blank-state {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  .voucher-list {
    flex: 1;
    .voucher-item {
      border-bottom: 1px solid rgba(91, 23, 28, 0.2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      cursor: pointer;
      > div {
        p {
          font-weight: 400;
        }
        .voucher-item_label {
          font-size: 0.75rem;
          color: rgba(51, 51, 51, 1);
          // color: rgba(var(--dark), 0.5);
          margin-bottom: 0.5rem;
          font-weight: 100;
          letter-spacing: 0.5px;
          opacity: 0.5;
        }
        .voucher-item_title {
          color: var(--gray-2);
          margin-bottom: 0.25rem;
          letter-spacing: 0.5px;
        }
        .voucher-item_code {
          color: var(--dark-red);
          margin-bottom: 0;
          font-size: 0.875rem;
          letter-spacing: 0.5px;
          &.is-used {
            color: var(--sea-green);
            font-weight: 700;
          }
          &.is-expired {
            color: var(--primary);
            font-weight: 700;
          }
        }
        .voucher-item_stamp {
          color: rgba(185, 122, 0, 1);
          font-size: 0.875rem;
          letter-spacing: 0.05em;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
    &.new {
      .voucher-item {
        background: var(--white);
        border-bottom: none;
        margin-bottom: 1rem;
        // padding: 1rem;
        border-radius: 0.25rem;
        .voucher-item-left {
          display: flex;
          gap: 1rem;
          position: relative;
          padding-left: 1rem;
          .voucher-left-img {
            position: absolute;
            height: calc(100% + 2rem);
            left: 0;
            top: -1rem;
            z-index: 0;
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
          }
        }
        .voucher-item__img {
          max-width: 78px;
          object-fit: contain;
          z-index: 1;
        }
        .voucher-item_title {
          font-weight: 600 !important;
        }
        .voucher-item_code {
          margin-bottom: 1rem;
          font-weight: 500 !important;
        }
      }
    }
  }
}

.modal-filter-voucher {
  .modal-title {
    color: var(--dark-red);
  }
}
.modal-detail-voucher {
  .modal-wrapper {
    background: rgba(var(--white), 0);
    .close {
      bottom: -4rem;
      top: unset;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid var(--white);
      border-radius: 100%;
      i {
        color: var(--white);
        font-size: 2rem;
      }
    }
    .modal-body {
      .modal-content {
        background: rgba(var(--white), 0);
        padding: 0.5rem;
        .modal-detail-voucher_wrapper {
          width: 100%;
          position: relative;
          .modal-detail-voucher_wrapper-img {
            position: absolute;
            top: -8rem;
            display: flex;
            justify-content: center;
            width: 100%;
            img {
              transform: rotate(-6.49deg);
            }
          }
          .modal-detail-voucher_top {
            background: url("/img/common/substract-top.png") center center
              no-repeat;
            background-size: contain;
            height: 250px;
            padding: 4.5rem 1rem 0rem 1rem;
            .modal-detail-voucher_top_title {
              color: var(--black);
              max-width: 65%;
            }
            .modal-detail-voucher_top_description {
              color: rgba(var(--gray-2), 0.7);
              font-size: 0.875rem;
            }
          }
          .modal-detail-voucher_bottom {
            background: url("/img/common/substract-bottom.png") center center
              no-repeat;
            background-size: contain;
            height: 230px;
            padding: 0rem 1rem 2.5rem 1rem;
            // margin-top: -2rem;
            text-align: center;
            .modal-detail-voucher_bottom_expired-date {
              color: var(--black);
              font-size: 0.625rem;
              padding-top: 2rem;
            }
            .modal-detail-voucher_bottom_code {
              margin-top: 0.5rem;
              color: var(--black);
            }
          }
        }
      }
    }
  }
}

.voucher-portal-class {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    opacity: 0.8;
  }
  .voucher-info-wrapper {
    width: 100%;
    z-index: 11;
    display: flex;
    align-items: center;
    .col-top {
      background: url("/img/common/substract-top.png") center center no-repeat;
      background-size: contain;
      height: 210px;
      position: relative;
      .img-wrapper {
        display: flex;
        justify-content: center;
        .img-product {
          position: absolute;
          top: -60%;
          width: 76%;
          height: 80%;
          img {
            object-fit: contain;
          }
        }
      }
      .main-info {
        width: 100%;
        max-width: 325px;
        .info-title {
          color: var(--blak);
          font-size: 1.5rem;
          font-weight: 700;
          margin: 0;
        }
        .info-desc {
          margin-top: 5px;
          color: var(--gray-2);
          font-weight: 100;
          font-size: 0.9rem;
          opacity: 0.7;
          margin-bottom: 0.5rem;
        }
        .info-expired {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          &.is-used {
            color: var(--sea-green);
            font-weight: 700;
          }
          &.is-expired {
            color: var(--red);
            font-weight: 700;
          }
        }
      }
    }
    .col-bottom {
      background: url("/img/common/substract-bottom.png") center center
        no-repeat;
      background-size: contain;
      padding: 1rem 0;
      height: 175px;
      // animation-duration: 5s;
      &.tear-off {
        animation: tear-animation 2.5s linear forwards;
      }
      .expired-date {
        font-size: 0.75rem;
        letter-spacing: 0.5px;
        text-align: center;
      }
      .barcode {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .barcode-info {
        letter-spacing: 0.5px;
      }
    }
    .close-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      &.claim {
        position: relative;
        bottom: -4rem;
      }
      .icon {
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        display: grid;
        place-items: center;
        position: relative;
        bottom: -40px;
        border-radius: 50%;
        border: 1.5px solid var(--white);
        i {
          color: var(--white);
          font-size: 1.5rem;
        }
      }
    }
  }
}

@keyframes tear-animation {
  0% {
    transform: rotate(0deg);
    top: 0;
    opacity: 1;
  }
  20% {
    transform: rotate(9deg) translateY(22px) translateX(-12px);
    opacity: 1;
  }
  70% {
    transform: translateY(30px);
    opacity: 1;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}
