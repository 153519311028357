@use "../scss/main-dart/variables/grid" as grid;

@use "../scss/main-dart/global";
@use "../scss/main-dart/overrider";

// $navbar-top-height-mobile: 0; // Set this to 0 if navbar top is not required
// $navbar-top-height-desktop: 0; // Set this to 0 if navbar top is not required

// $navbar-main-height-mobile: 67px; // Height on mobile
// $navbar-main-height-desktop: 67px; // Height on desktop

// $navbar-bottom-height-mobile: 60px; // Height on mobile
// $navbar-bottom-height-desktop: 60px; // Height on desktop

// $navbar-height-mobile: $navbar-top-height-mobile + $navbar-main-height-mobile;
// $navbar-height-desktop: $navbar-top-height-desktop + $navbar-main-height-desktop;

// @use "../../components/global/Navbar/navbar";
// @use "../../components/global/Footer/footer";
// @use "../../components/global/CookieConsent/cookie-consent";

// @use "../../components/anti/Modal/modal-custom";
// @use "../../components/anti/Buttons/buttons";
// @use "../../components/anti/Breadcrumb/breadcrumb";
// @use "../../components/anti/Card/card";

// @use "../../components/anti/Image/image";

// Custom variables
// EDIT THESE VARIABLES
// @import "variables/navbar"; // Set navbar height, animation duration, and other variables

@use "../../components/anti/Navbar/navbar";
// @use "../../components/anti/navbar/navbar";
@use "../../components/anti/Image/image";
@use "../../components/anti/Modal/modal";
@use "../../components/anti/Modal/modal-custom";
@use "../../components/anti/Tabs/tabs";
@use "../../components/anti/Tabs/tabs-custom";
@use "../../components/anti/Button/button";
@use "../../global/toast/toast";
@use "../../global/countdown-timer/countdown-timer";
@use "../../components/anti/Forms/forms";
@use "../../components/anti/Forms/forms-custom";

// User Style
@use "../scss/pages/home";
@use "../scss/pages/otp";
@use "../scss/pages/barcode";
@use "../scss/pages/empty-state";
@use "../scss/pages/user-history";
@use "../scss/pages/user-register";
@use "../scss/pages/settings";
@use "../scss/pages/login";
@use "../scss/pages/error";
@use "../scss/pages/whatsapp-verify";

// Staff Style
@use "../scss/pages/vouchers";

// Custom Mixins ================================================= //
// Custom Mixins ================================================= //

::-moz-scrollbar-button:decrement,
::-moz-scrollbar-button:increment,
::-webkit-scrollbar-button:decrement,
::-webkit-scrollbar-button:increment {
  width: 0px;
}

//FRAME
.mobile-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 1.5rem 0;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  background-color: var(--gray-100);
  @media (max-width: 512px) {
    padding: 0;
  }
  .screen {
    position: relative;
    &.user-home {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("/img/bg/bg-red.png");
    }
    &.bg-yellow {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("/img/bg/bg-yellow-home.png");
    }
    border: 1px solid var(--gray-300);
    width: 412px;
    height: 100%;
    background-color: var(--bg-primary);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    @media (max-width: 512px) {
      width: 100%;
      border: none;
    }
    .main-page {
      overflow-y: scroll;
      flex: 1;
    }
  }
}

.pointer {
  cursor: pointer;
}

//AUTH LAYOUT
.auth-layout {
  .back {
    display: flex;
    align-items: center;
    gap: 15px;
    color: var(--black);
    font-size: 0.75rem;
    p {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.desktop-frame {
  overflow-y: scroll;
  background-color: var(--bg-primary);
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  main {
    overflow-y: scroll;
    &.red {
      background-color: var(--red);
    }
    &.yellow {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("/img/bg/bg-yellow-home.png");
    }
    padding-top: 80px;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    .desktop-menu {
      padding: 3rem 6rem !important;
      position: relative;
      @media (max-width: 1300px) {
        padding: 3rem 0;
      }
      @media (max-width: grid.$lg) {
        padding: 0 0;
      }
      .navigations {
        position: sticky;
        top: 30px;
        padding-left: 0;
        padding-top: 1.25rem;
        min-height: 605px;
        background-color: var(--white);
        border: 1px solid var(--light-dark);
        border-radius: 10px;
        .navigation-item {
          height: 56px;
          padding: 1.5rem 0 1.5rem 2.25rem;
          box-sizing: border-box;
          list-style: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          color: rgba(22, 43, 58, 0.6);
          font-size: 1rem;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          &.active {
            background-color: rgba(130, 24, 31, 0.1);
            border-style: outset;
            box-sizing: border-box;
            border-bottom: none;
            border-right: none;
            color: var(--black);
            font-weight: bold;
            position: relative;
          }
          &:not(:last-child) {
            border-bottom: 1px solid var(--ivory);
          }
          &.logout {
            color: var(--red);
          }
          .active-sign {
            position: absolute;
            top: 0;
            left: -2px;
            height: 100%;
            width: 5px;
            background-color: rgba(130, 24, 31, 1);
          }
          &.footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 0;
            background: #fffbf4;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            a {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding: 1.5rem 2.25rem;
              .site-by {
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.0375rem;
                color: #6d5d48;
                display: flex;
                align-items: center;
                gap: 0.5rem;
              }
              img {
                object-fit: contain;
              }
            }
          }
        }
      }
      .page-section {
        background-color: var(--white);
        @media (min-width: grid.$lg) {
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
        }
        // background-color: var(--white);
        min-height: 605px;
        border-radius: 10px;
      }
    }
  }
}

// ==========================================================================
// NProgress
// ==========================================================================

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--gold);
  position: fixed;
  z-index: 1031;
  top: 60px;
  @media (min-width: grid.$lg) {
    top: 79px;
  }
  width: 100%;
  height: 3px;
}
//LOADING FULL
.full-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  opacity: 0.8;
  height: 100%;
  background-color: var(--black);
}

.sc-voucher {
  height: 100%;
  .divider {
    height: 300px;
  }
  .voucher-content {
    height: 500px;
    background-color: red;
  }
}

// TYPOGRAPHY
.title-text {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--dark-red);
  line-height: 2rem;
  margin-bottom: 1rem;
  &.center {
    text-align: center;
  }
  &.uppercase {
    text-transform: uppercase;
  }
}

.text-gray {
  color: rgba(51, 51, 51, 1) !important;
  opacity: 0.7;
}

.form-placeholder {
  &::placeholder {
    font-weight: 100 !important;
    letter-spacing: 0.5px;
  }
}

.letter-normal {
  letter-spacing: 0.5px;
}

.modal-camera {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  .col-top {
    flex: 1;
  }
  .col-bottom {
    flex: 1;
  }
}

.success-reward {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .col-top {
    background-color: var(--yellow);
    height: 200px;
  }
  .col-bottom {
    position: relative;
    .success-img-wrapper {
      width: 100%;
      position: absolute;
      top: -60px;
      display: flex;
      justify-content: center;
    }
    flex: 1;
    background-color: var(--bg-primary);
    .info {
      height: 100%;
      position: relative;
      .info-title {
        color: var(--dark-red);
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
    }
    .btn-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
}

* {
  letter-spacing: 0.5px;
}

.infinite-scroll-component {
  overflow: unset !important;
}

.pointer-none {
  pointer-events: none;
}
