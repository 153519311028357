.sc-tabs {
  .tabs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: 1px solid var(--dark-red);
  }
  a.tabs-item,
  .tabs-item {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1;
    font-weight: 500;
    color: var(--gray-3);
    border-radius: 20px;
    background-color: var(--bg-primary);
    border: 1px solid var(--bg-primary);
    &.active {
      background-color: var(--dark-red);
      border: 1px solid var(--dark-red);
      color: var(--white);
    }
  }
}
